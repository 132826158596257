// DEFAULT COLOR SCHEME:
:root {
    --color-white: #fff;
    --color-smoke-white: #f7f9fb;
    --color-darker-white: #f9fafc;
    --color-primary-gradient: linear-gradient(90deg, #5e38e7 0%, #2b5fc0 100%);
    --color-secondary-gradient: linear-gradient(45deg, #5430d8 0%, #2b5fc0 100%);
    --color-dark-gradient: linear-gradient(90deg, #21222f 0%, #151620 100%);
    --color-gray-gradient: linear-gradient(180deg, #fff 0%, #dedee0 150%);
    --color-primary-purple: #6b46ff;
    --color-secondary-mint: #0bcaf9;
    --color-secondary-mint-05: #0bcaf90d;
    --color-warning-red: #fe3e7f;
    --color-blue: #0b6ef9;
    --color-dark-blue: #121422;
    --color-green: #1eb53a;
    --color-aqua: #0bcaf9;
    --color-light-gray: #dedee0;
    --color-gray: #9ba4b5;
    --color-silver-gray: #868e9e;
    --color-matte-gray: #34364a;
    --color-dark-gray: #2e3042;
    --color-black: #0a0c16; 
    --color-shadow-1: #1b2b29;
    --color-shadow-2: #445154;
    --color-welcome-screen-accent: #6b46ff;
    --color-light-background: #f9fafc;
    --color-transparent: rgba(0, 0, 0, 0);
    --color-warning: #fe9339;
}

$color-inherit: inherit;

$color-white: var(--color-white);
$color-smoke-white: var(--color-smoke-white);
$color-darker-white: var(--color-darker-white);

$color-primary-gradient: var(--color-primary-gradient);
$color-secondary-gradient: var(--color-secondary-gradient);
$color-dark-gradient: var(--color-dark-gradient);
$color-gray-gradient: var(--color-gray-gradient);

$color-primary-purple: var(--color-primary-purple);
$color-secondary-mint: var(--color-secondary-mint);
$color-secondary-mint-05: var(--color-secondary-mint-05);

$color-warning-red: var(--color-warning-red);
$color-blue: var(--color-blue);
$color-dark-blue: var(--color-dark-blue);
$color-green: var(--color-green);
$color-aqua: var(--color-aqua);

$color-light-gray: var(--color-light-gray);
$color-gray: var(--color-gray);
$color-silver-gray: var(--color-silver-gray);
$color-matte-gray: var(--color-matte-gray);
$color-dark-gray: var(--color-dark-gray);

$color-black: var(--color-black);

$color-shadow-1: var(--color-shadow-1);
$color-shadow-2: var(--color-shadow-2);

$color-welcome-screen-accent: var(--color-welcome-screen-accent);
$color-light-background: var(--color-light-background);

$color-transparent: var(--color-transparent);

$color-white-05: rgba(#fff, 0.5);
$color-black-02: rgba(#0a0c16, 0.2);

$color-lighter-grey: #e8e9ec;

$color-secondary-mint-04: rgba(#0bcaf9, 0.4);
$color-secondary-mint-01: rgba(#0bcaf9, 0.1);
$color-secondary-mint-005: rgba(#0bcaf9, 0.05);

$color-warning: var(--color-warning);