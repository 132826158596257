@import "shared/styles/index.scss";

.customCalendar {
  max-width: 350px;
  background: $color-white;
  border: thin solid $color-light-gray;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(27, 43, 41, 0.08);
  border-radius: 10px;
  font-family: $ff-main;

  .react-calendar__navigation__next2-button,
  .react-calendar__navigation__prev2-button {
    display: none;
  }

  .react-calendar__navigation__next-button,
  .react-calendar__navigation__prev-button {
    min-width: 44px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px 6px;
    background: #f9fafc;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .react-calendar__navigation__next-button {
    border-radius: 0px 8px 0px 0;
  }

  .react-calendar__navigation__prev-button {
    border-radius: 8px 0 0 0;
  }

  .react-calendar__navigation__next-button:disabled,
  .react-calendar__navigation__prev-button:disabled {
    background-color: #f0f0f0;
    cursor: not-allowed;
  }

  .react-calendar__month-view__weekdays {
    margin-bottom: 6px;
  }

  .react-calendar__month-view {
    padding: 0 9px;

    &__days {
      margin-bottom: 8px;
    }
  }

  .react-calendar__navigation__label {
    &:disabled {
      background: $color-darker-white;
    }
  }

  .react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f9fafc;
    border-radius: 9px 9px 0 0;
    height: 49px;
    font-weight: 700;
    color: #2e3042;
  }

  .react-calendar__navigation__label {
    font-family: $ff-main;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: $color-dark-gray;
    background: $color-darker-white;
    border: none;
  }

  .react-calendar__tile {
    font-family: $ff-main;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 16px;
    text-align: center;
    color: #2e3042;
    flex-basis: 10.2857% !important;
    padding: 7px;
    margin: 0 2%;
    border: 1px solid transparent;
    background: $color-white;
    cursor: pointer;

    &:disabled {
      color: $color-silver-gray;
      background: $color-white;
      cursor: not-allowed;
    }

    &--active {
      background: linear-gradient(0deg, rgba(11, 202, 249, 0.05), rgba(11, 202, 249, 0.05)), $color-white;
      border: 1px solid #0bcaf9;
      box-sizing: border-box;
      box-shadow: 0px 4px 10px rgba(27, 43, 41, 0.08);
      border-radius: 10px;
      font-weight: 700;

      &:enabled:focus {
        background: linear-gradient(0deg, rgba(11, 202, 249, 0.05), rgba(11, 202, 249, 0.05)), $color-white;
      }

      &:enabled:hover {
        background: linear-gradient(0deg, rgba(11, 202, 249, 0.05), rgba(11, 202, 249, 0.05)), $color-white;
      }
    }
  }

  .react-calendar__month-view__weekdays__weekday {
    font-family: $ff-main;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: $color-silver-gray;

    abbr {
      text-transform: capitalize;
      text-decoration: none;
    }
  }
}
