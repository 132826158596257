@import "./typography.scss";
@import "./colors.scss";

$ff-lato: 'Lato-Regular';
$ff-ibm: 'IBMPlexSansCondensed-Regular';
$ff-mazda: 'MazdaType-Regular';
$ff-main: var(--font-main);
$ff-second: var(--font-second);

@font-face {
  font-family: $ff-lato;
  src: local("Lato"), url(../../assets/fonts/Lato-Regular.ttf) format("truetype"),
    url(../../assets/fonts/Lato-Regular.woff2) format("woff2"), url(../../assets/fonts/Lato-Regular.woff) format("woff");
}
@font-face {
  font-family: $ff-ibm;
  src: local("IBMPlex"), url(../../assets/fonts/IBMPlexSansCond.ttf) format("truetype"),
    url(../../assets/fonts/IBMPlexSansCond.woff2) format("woff2"),
    url(../../assets/fonts/IBMPlexSansCond.woff) format("woff");
}

@font-face {
  font-family: $ff-mazda;
  src: url(../../assets/fonts/MazdaType-Regular.ttf) format('truetype');
}

body {
  --font-main: #{$ff-lato};
  --font-second: #{$ff-ibm};
  font-family: $ff-main;
  background: $color-smoke-white;
  font-size: $fs-regular;
  margin: 0;
}

:global(.make-mazda) {
  --font-main: #{$ff-mazda};
  --font-second: #{$ff-mazda};
  font-family: $ff-main;
}

.appRoot {
  position: relative;
  display: flex;
  overflow-x: hidden;
  background: $color-smoke-white;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: hidden;
}

* {
  box-sizing: border-box;
}

::-ms-clear {
  display: none;
}

::-ms-reveal {
  display: none;
}

p {
  margin: 0;
}

:global(.make-mazda button) {
  text-transform: uppercase;
}