@import "shared/styles/index.scss";
@import "shared/styles/chat-input.scss";

.container {
  background-color: $color-white;
  box-shadow: 0 -4px 10px 0 rgba(#445154, 0.1);

  .header {
    padding: 15px 20px;
  }
  .title {
    font-weight: bold;
    font-size: $fs-regular;
  }

  .subtitle {
    margin-top: 0.5em;
    color: $color-silver-gray;
    font-size: $fs-smaller;
  }

  .canvasWrapper {
    background-color: $color-darker-white;
    position: relative;
    width: 100vw;
    text-align: center;
  }

  .placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $color-silver-gray;
    font-style: italic;
    text-align: center;
    user-select: none;
  }

  .clearBtn {
    position: absolute;
    padding: 25px;
    right: 0;
    top: 0;
    color: $color-silver-gray;
    user-select: none;
  }
}
