$fs-tiny: 10px;
$fs-smaller: 13px;
$fs-small: 14px;
$fs-regular: 15px;
$fs-slightly-bigger: 16px;
$fs-regular-bigger: 16px;
$fs-bigger: 17px;
$fs-much-bigger: 18px;
$fs-big: 20px;
$fs-very-big: 22px;
$fs-large: 28px;
$fs-very-large: 40px;
$fs-maxi: 55px;
$fs-huge: 72px;

$fw-bold: bold;
