@import "./colors.scss";
@import "./mixins.scss";

.chatInput {
  @include flex-centered;

  box-sizing: border-box;
  width: 100vw;
  max-width: 100%;
  padding: 15px 20px;
  background-color: $color-white;
  box-shadow: 0 -4px 10px 0 rgba(#445154, 0.1);

  button + button {
    margin-left: 15px;
  }
}
