.packageItemsPrice {
  margin-right: 15px;
  text-decoration: line-through;
  color: $color-warning-red;
  font-size: $fs-smaller;
}

.packageItemsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .packageItemsTitle {
    margin-left: 0;
    margin-top: 0.5em;
  }

  span {
    color: $color-silver-gray;
    font-family: $ff-main;
    font-size: $fs-smaller;
    margin-left: 1em;
    margin-top: 2px;
  }
}
