@mixin _position($position, $args) {
  @each $dir in top, left, bottom, right {
    $i: index($args, $dir);

    @if $i {
      #{$dir}: nth($args, $i + 1);
    }
  }

  position: $position;
}

@mixin absolute($args) {
  @include _position(absolute, $args);
}

@mixin relative($args) {
  @include _position(relative, $args);
}

@mixin fixed($args) {
  @include _position(fixed, $args);
}

@mixin sizing($args, $prefix: "") {
  $width: if(length($args) == 2, nth($args, 1), $args);
  $height: if(length($args) == 2, nth($args, 2), $args);

  #{$prefix}width: $width;
  #{$prefix}height: $height;
}

@mixin min-sizing($args) {
  @include sizing($args, "min-");
}

@mixin max-sizing($args) {
  @include sizing($args, "max-");
}

@mixin circle($args) {
  @include sizing($args);

  border-radius: 50%;
}

@mixin flex-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin vertical-center($total_height, $content_height) {
  $padding_height: calc((#{$total_height} - #{$content_height}) / 2);

  height: calc(#{$total_height} - 2 * #{$padding_height});
  padding: #{$padding_height} 0;
}
