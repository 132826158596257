@import "./colors.scss";
@import "./typography.scss";

.box {
  background: $color-white;
  border: $color-light-gray 1px solid;
  border-radius: 10px;
  box-shadow: 0 4px 10px 0 rgba(#1b2b29, 0.08);
  & + & {
    margin-top: 10px;
  }
  & > div:first-child,
  &__header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  & > div:last-child,
  &__body {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  &__header {
    background: $color-darker-white;
    padding: 12px 14px;
  }
  &__body {
    padding: 15px;
  }
  &__body:empty,
  &__box_nopad {
    padding: 0;
  }
  &__body + &__header,
  &__header + &__header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: $color-light-gray 1px solid;
  }
  &__header + &__body {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.data {
  font-size: $fs-smaller;
  & + & {
    margin-top: 5px;
  }

  &Label {
    color: $color-silver-gray;
  }
}
